































import { ref as _ref } from "@vue/composition-api";
import Agencies, { AgencyData } from "@/select2/agency";
import Pagination from "@/components/Pagination/index.vue";
import router from "@/router";
import { defaultListQuery } from "@/select2/api/base";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const isAdmin = User.isAdmin;

  let count = _ref(0);

  let listQuery = _ref({ ...defaultListQuery,
    ordering: "name"
  });

  let loading = _ref(true);

  let agencies = _ref(([] as AgencyData[]));

  async function load() {
    loading.value = true;
    const data = await Agencies.list(listQuery.value);
    agencies.value = data.results;
    count.value = data.count;
    loading.value = false;
  }

  async function search() {
    listQuery.value.page = 1;
    load();
  }

  onMounted(() => {
    load();
  });
  return {
    count,
    listQuery,
    loading,
    agencies,
    load,
    search
  };
};

__sfc_main.components = Object.assign({
  Pagination
}, __sfc_main.components);
export default __sfc_main;
